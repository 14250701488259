
/* 
   This will accept the total minutes of work and return
   the formatted time in Weeks, Days, Hours, and Minutes 
*/
export const formatWorkTime = (workTime: number) => {

    if(isNaN(workTime) || workTime === 0) return "-";

    let weeks = Math.floor(workTime / 2400);  
    let days = Math.floor((workTime - (weeks * 2400)) / 480);
    let hours = Math.floor((workTime - (weeks * 2400) - (days * 480)) / 60);
    let minutes = Math.floor(workTime - (weeks * 2400) - (days * 480) - (hours * 60));

    let fWeeks = (weeks > 0) ? weeks + "W " : "";
    let fDays = (days > 0) ? days + "D " : "";
    let fHours = (hours > 0) ? hours + "H " : "";
    let fMinutes = (minutes > 0) ? minutes + "M " : "";

    let formattedWork = fWeeks + fDays + fHours + fMinutes;
    return formattedWork;
}