import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../Core/hooks';
import { deleteList, minimizeList, getProject, setLists } from '../Store/Project';
import { ContextMenu } from '../Models/ContextMenu';
import { ListContextMenuParam } from'../Models/Requests/ListContexMenuParam';
import { AiOutlineClose } from "react-icons/ai";
import { List } from '../Models/List';

interface Props {
    params: ListContextMenuParam | undefined;    
}

function ListContextMenu(props: Props) {

    const dispatch = useAppDispatch();
    const projectState = useAppSelector(getProject);
    const [listContextMenu, setListContextMenu] = useState<ContextMenu>();
  
    useEffect(() => {

        let project = projectState.projects.find(p => p.loaded);
        //if (project && props.params) {
        if (project?.id !== props.params?.projectId) {
            setListContextMenu({ id: 0, x: 0, y: 0, width: 0, height: 0 });
          //  }
        } else {
            if (project && props.params) {
                let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
                if ( project.id === props.params.projectId && ((listContextMenu && listContextMenu.id !== props.params.listId) || !listContextMenu)) {
                    if (props.params.event) {
                        let menuWidth = window.outerWidth / 5;
                        let menuHeight = window.outerHeight / 2;
                        let menuXStart = props.params.event.clientX - 10;
                        let menuYStart = props.params.event.clientY + 20

                        if (menuXStart + menuWidth > window.outerWidth) {
                            menuXStart = window.outerWidth - menuWidth - 10;
                        }

                        contextMenu.id = props.params.listId;
                        contextMenu.x = menuXStart;
                        contextMenu.y = menuYStart;
                        contextMenu.width = menuWidth;
                        contextMenu.height = menuHeight;
                    }
                }
                setListContextMenu(contextMenu);
            }
        }
    }, [projectState.projects, props.params]);

    const closeContextMenu = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        closeMenu();
    }

    const closeMenu = () => {
        let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
        setListContextMenu(contextMenu);
    }

    const trashList = (e: React.MouseEvent<HTMLDivElement>) => {
        
        e.preventDefault();
        e.stopPropagation();

        if (listContextMenu) {
            // delete on the backend
            dispatch(deleteList(listContextMenu.id));
            // delete on front end
            const lists: Array<List> = [...projectState.lists];
            const index = projectState.lists.findIndex(l => l.id === listContextMenu.id);
            lists.splice(index, 1);
            dispatch(setLists(lists));
            closeMenu();
        }
    }

    const handleMinimizeList = (e: React.MouseEvent<HTMLDivElement>, minimize: boolean) => {
        
        e.preventDefault();
        e.stopPropagation();

        if (listContextMenu) {
            dispatch(minimizeList({id: listContextMenu.id, minimize: minimize}));
            //const lists: Array<List> = [...projectState.lists];
            //const index = projectState.lists.findIndex(l => l.id === listContextMenu.id);
            //lists[index].minimized = minimize;
            //dispatch(setLists(lists));
            closeMenu();
        }
    }


    const sContextMenu: React.CSSProperties = {
        top: (listContextMenu) ? listContextMenu.y : 0,
        left: (listContextMenu) ? listContextMenu.x : 0,
        width: (listContextMenu) ? listContextMenu.width : 0,
        height: (listContextMenu) ? listContextMenu.height : 0,
    }

    const list = projectState.lists.find(l => l.id === listContextMenu?.id);

    return (
        <>
            {listContextMenu && listContextMenu.id > 0 &&
                <div onClick={e => closeContextMenu(e)} className="popupContainer">
                    <div className="contextMenu" style={sContextMenu} onClick={e => { e.preventDefault(); e.stopPropagation()}}>
                        <h1>List Options</h1>
                        <div className="divClose">
                            <Link to="" onClick={(e) => closeContextMenu(e)}>
                                <AiOutlineClose color="#555" size="13" />
                            </Link>
                        </div>
                        <div className="divDeleteList" onClick={(e) => trashList(e)}>
                            Delete List
                        </div>
                        {list?.minimized ?
                            <div className="divDeleteList" onClick={(e) => handleMinimizeList(e, false)}>
                                Maximize List
                            </div>  
                        :
                            <div className="divDeleteList" onClick={(e) => handleMinimizeList(e, true)}>
                                Minimize List
                            </div>
                        }
                    </div>
                </div>
            }
        </>
    );
}

export default ListContextMenu;