import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../Core/hooks';
import { getProject, setReportSort, setReportSortDirection } from '../Store/Project';
import { ContextMenu } from '../Models/ContextMenu';
import { GroupCardContextMenuParams } from '../Models/Requests/GroupCardContextMenuParams';
import { AiOutlineClose } from "react-icons/ai";
import { REPORT_SORTS } from '../Helpers/Constants';

interface Props {
    params: GroupCardContextMenuParams | undefined;    
}

function GroupCardContextMenu(props: Props) {

    const dispatch = useAppDispatch();
    const projectState = useAppSelector(getProject);
    const [groupCardContextMenu, setGroupCardContextMenu] = useState<ContextMenu>();

    useEffect(() => {
        if (props.params) {
            let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
            if ((groupCardContextMenu && groupCardContextMenu.id !== props.params.id) || !groupCardContextMenu) {

                //let menuWidth = window.outerWidth / 5;
                //let menuWidth = 300;
                //let menuHeight = window.outerHeight * .7;

                //let numberOfLabels = projectState.labels.length;
                //let menuHeight = 145 + 50 * numberOfLabels;

                let menuXStart = props.params.event.clientX + 15;
                let menuYStart = props.params.event.clientY - 15;
                //alert(menuXStart + " : " + menuWidth + " : " + window.outerWidth);
                if (menuXStart > window.outerWidth) {
                    menuXStart = window.outerWidth - 10;
                }
                /*
                if (menuXStart + menuWidth > window.outerWidth) {
                    menuXStart = window.outerWidth - menuWidth - 10;
                }
                */
                contextMenu.id = props.params.id;
                contextMenu.x = menuXStart;
                contextMenu.y = menuYStart;
                //contextMenu.width = menuWidth;
                //contextMenu.height = menuHeight;
            }
            setGroupCardContextMenu(contextMenu);
           // refreshLabelSelections();
        }
    }, [props.params]);

    const closeContextMenu = (e?: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement>) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
        setGroupCardContextMenu(contextMenu);
    }

    const handleGroupChange = (e: React.MouseEvent<HTMLDivElement>, value: string) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (props.params && props.params.id && value !== "") {
            //setFilter(value);
            if (projectState.reportSort === value) {
                if (projectState.reportSortDirection === "" || projectState.reportSortDirection === "asc") {
                    dispatch(setReportSortDirection("desc"));
                } else {
                    dispatch(setReportSortDirection("asc"));
                }
            } else {
                dispatch(setReportSortDirection("desc"));
            }
            dispatch(setReportSort(value));
            closeContextMenu();
        }
    }

    const sContextMenu: React.CSSProperties = {
        top: (groupCardContextMenu) ? groupCardContextMenu.y : 0,
        left: (groupCardContextMenu) ? groupCardContextMenu.x : 0,
        width: "200px",
        height: "auto",
        paddingBottom: "15px",
    }

    let reportGroups = REPORT_SORTS;
    const mappedReportGroups = reportGroups.map((value, index) => {
        let popup = "reportFilterPopup";
        let selected = (projectState.reportSort === value) ? "reportFilterSelected" : "";
        return (
            <div className={`${popup} ${selected}`} onClick={(e) => handleGroupChange(e, value)}>
                {value} {projectState.reportSort === value && ` (${projectState.reportSortDirection})`}
            </div>
        );
    });

    return (
        <>
        {groupCardContextMenu && groupCardContextMenu.id > 0 &&
            <div onClick={e => closeContextMenu(e)} className="popupContainer">
                <div className="contextMenu" style={sContextMenu} onClick={e => { e.preventDefault(); e.stopPropagation()}}>
                    <h1 style={{ marginBottom: "0px" }}>Sorted By</h1>
                    <div className="divClose">
                        <Link to="" onClick={(e) => closeContextMenu(e)}>
                            <AiOutlineClose color="#555" size="13" />
                        </Link>
                    </div>
                    <div style={{ textAlign: "center", paddingTop: "10px" }}>
                        {mappedReportGroups}
                    </div>
                </div>
            </div>
        }
        </>
    );
}

export default GroupCardContextMenu;