import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../Core/store';
//import { Credentials } from '../Models/Requests/Credentials';
import { axiosPublic, axiosPrivate } from '../Core/axios';
import { Project } from '../Models/Project';
import { List } from '../Models/List';
import { sliceStatus } from '../Helpers/types';
//import { decodeToken } from '../Helpers/DecodeToken';
import { NewListParams } from '../Models/Requests/NewListParams';
import { Settings } from 'http2';
//import { userSliceStatus } from '../Helpers/types';
//import { PasswordReset } from '../Models/Requests/PasswordReset';

export interface SettingsState {
  showProjects: boolean;
  status: sliceStatus;
  errorMessage: string;
  avatar: string;
  showingHelp: boolean;
  defaultAvatarColor: string;
  defaultAvatarFontColor: string;
  userId: number;
  timeZone: string;
}

const initialState: SettingsState = {
  showProjects: false,
  status: "unset",
  errorMessage: "",
  avatar: "",
  showingHelp: false,
  defaultAvatarColor: "",
  defaultAvatarFontColor: "",
  userId: 0,
  timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
};

export const saveSidebarSetting = createAsyncThunk(
  'api/settings/save/sidebar',
  async (show: boolean) => {    
    return await axiosPrivate.put('api/settings',
      JSON.stringify({
        show: show,
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        /* update frontend */
        //setShowSidebar(res.data.data);
        return res.data.showProjects;
      }
    );
});

export const saveAvatar = createAsyncThunk(
  'api/settings/save/avatar',
  async (avatar: string) => {    
    return await axiosPrivate.put('api/settings/avatar',
      JSON.stringify({
        avatar: avatar,
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data.avatar;
      }
    );
});

export const removeAvatar = createAsyncThunk(
  'api/settings/remove/avatar',
  async () => {    
    return await axiosPrivate.delete('api/settings/avatar',
      {
        withCredentials: true
      }
    ).then(
      (res) => {
        return "";
      }
    );
});

export const getUserSettings = createAsyncThunk(
  'api/settings/get/user/settings',
  async () => {    
    return await axiosPrivate.get('api/settings',
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const heartBeat = createAsyncThunk(
  'api/settings/heartbeat',
  async () => {    
    return await axiosPrivate.get('api/settings/heartbeat',
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const setShowHelp = createAsyncThunk(
'setShowHelp',
  async (show: boolean) => {    
      return show;
});

export const SettingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
    /*
      .addCase(showSidebar.pending, (state) => {
        state.status = 'loading';
      })*/
      .addCase(saveSidebarSetting.fulfilled, (state, action) => {
        state.status = 'idle';
        state.showProjects = action.payload;
      })
      .addCase(saveSidebarSetting.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(saveAvatar.fulfilled, (state, action) => {
        state.status = 'idle';
        state.avatar = action.payload;
      })
      .addCase(saveAvatar.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(removeAvatar.fulfilled, (state, action) => {
        state.status = 'idle';
        state.avatar = action.payload;
      })
      .addCase(removeAvatar.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(getUserSettings.fulfilled, (state, action) => {
        state.status = 'idle';
        let settings: SettingsState = action.payload;
        state.showProjects = settings.showProjects;
        state.avatar = settings.avatar;
        state.defaultAvatarColor = settings.defaultAvatarColor;
        state.defaultAvatarFontColor = settings.defaultAvatarFontColor;
        state.userId = settings.userId;
      })
      .addCase(getUserSettings.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
      .addCase(setShowHelp.fulfilled, (state, action) => {
        state.status = 'idle';
        state.showingHelp = action.payload;
      })
  },
});

export const getSettings = (state: RootState) => state.settings;
export default SettingsSlice.reducer;
