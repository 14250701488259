import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../Core/hooks';
import { getProject, setReportFilters, setReportFilterValues } from '../Store/Project';
import { ContextMenu } from '../Models/ContextMenu';
import { FilterCardContextMenuParams } from '../Models/Requests/FilterCardContextMenuParams';
import { AiOutlineClose } from "react-icons/ai";
import { CARD_STATUSES, REPORT_FILTERS } from '../Helpers/Constants';

interface Props {
    params: FilterCardContextMenuParams | undefined;    
}

function FilterCardContextMenu(props: Props) {

    const dispatch = useAppDispatch();
    const projectState = useAppSelector(getProject);
    const [filterValue, setFilterValue] = useState("");
    //const [filter, setFilter] = useState("");
    const [filterCardContextMenu, setFilterCardContextMenu] = useState<ContextMenu>();

    useEffect(() => {
        if (props.params) {
            let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
            if ((filterCardContextMenu && filterCardContextMenu.id !== props.params.id) || !filterCardContextMenu) {

                //let menuWidth = window.outerWidth / 5;
                //let menuWidth = 300;
                //let menuHeight = window.outerHeight * .7;

                //let numberOfLabels = projectState.labels.length;
                //let menuHeight = 145 + 50 * numberOfLabels;

                let menuXStart = props.params.event.clientX + 15;
                let menuYStart = props.params.event.clientY - 15;
                //alert(menuXStart + " : " + menuWidth + " : " + window.outerWidth);
                if (menuXStart > window.outerWidth) {
                    menuXStart = window.outerWidth - 10;
                }
                /*
                if (menuXStart + menuWidth > window.outerWidth) {
                    menuXStart = window.outerWidth - menuWidth - 10;
                }
                */
                contextMenu.id = props.params.id;
                contextMenu.x = menuXStart;
                contextMenu.y = menuYStart;
                //contextMenu.width = menuWidth;
                //contextMenu.height = menuHeight;
            }
            setFilterCardContextMenu(contextMenu);
           // refreshLabelSelections();
        }
    }, [props.params]);

    const closeContextMenu = (e?: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement>) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        setFilterValue("");
        let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
        setFilterCardContextMenu(contextMenu);
    }

    const handleFilterChange = (e: React.MouseEvent<HTMLDivElement>, value: string) => {
        e.preventDefault();
        e.stopPropagation();
        
        if (props.params && props.params.id && value !== "") {
            let filters = [...projectState.reportFilters];
            if (!projectState.reportFilters.includes(value)){
                filters.push(value);
            } else {
                const indexToRemove = filters.findIndex(f => f === value);
                filters.splice(indexToRemove, 1);
                /* we have to remove all values because we don't know which value goes with which filter */
                dispatch(setReportFilterValues([]));
            }
            dispatch(setReportFilters(filters));
            //closeContextMenu();
        }
    }
    
    const handleFilterValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        //e.preventDefault();
        //e.stopPropagation();
        //if (e.currentTarget.value !== "") {
            setFilterValue(e.currentTarget.value);
        //}
    }

    const addFilterValue = (e: React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) => {
        //e.preventDefault();
        //e.stopPropagation();

        let update = false;

        if (e.type === "blur") {
            update = true;
        } else if (e.type === "keydown") {
            let event = e as React.KeyboardEvent<HTMLInputElement>;
            if (['Enter'].indexOf(event.key) !== -1 && filterValue !== "") {
                update = true;
            }
        }

        if (update) {
            let filterValues = [...projectState.reportFilterValues];
            if (!filterValues.includes(filterValue) && filterValue !== "") {
                filterValues.push(filterValue);
                dispatch(setReportFilterValues(filterValues));
                setFilterValue("");
            }
            //console.log(filterValues);
            //closeContextMenu();
        }
    }

    const sContextMenu: React.CSSProperties = {
        top: (filterCardContextMenu) ? filterCardContextMenu.y : 0,
        left: (filterCardContextMenu) ? filterCardContextMenu.x : 0,
        width: "250px",
        height: "auto",
        paddingBottom: "15px",
    }

    //const card = projectState.cards.find(c => c.id === props.params?.id);
    let reportFilters = REPORT_FILTERS;
    const mappedReportFilters = reportFilters.map((value, index) => {
        let popup = "reportFilterPopup";
        let selected = (projectState.reportFilters.includes(value)) ? "reportFilterSelected" : "";
        return (
            <div style={{ marginBottom: "2px" }} className={`${popup} ${selected}`} onClick={(e) => handleFilterChange(e, value)}>
                {value}
            </div>
        );
    });

    return (
        <>
        {filterCardContextMenu && filterCardContextMenu.id > 0 &&
            <div onClick={e => closeContextMenu(e)} className="popupContainer">
                <div className="contextMenu" style={sContextMenu} onClick={e => { e.preventDefault(); e.stopPropagation()}}>
                    <h1 style={{ marginBottom: "0px" }}>Filter By</h1>
                    <div className="divClose">
                        <Link to="" onClick={(e) => closeContextMenu(e)}>
                            <AiOutlineClose color="#555" size="13" />
                        </Link>
                    </div>
                    <div style={{ textAlign: "center", paddingTop: "10px" }}>
                        <input type="text" 
                            className="addContainerInput"
                            value={filterValue}
                            //autoFocus
                            onChange={(e) => handleFilterValueChange(e)} 
                            onKeyDown={(e) => addFilterValue(e)}
                            onBlur={(e) => addFilterValue(e)}
                            />
                    </div>
                    <div style={{ textAlign: "center" }}>
                        {mappedReportFilters}
                    </div>
                </div>
            </div>
        }
        </>
    );
}

export default FilterCardContextMenu;