import React from 'react';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppSelector, useAppDispatch } from '../../Core/hooks';
import { getUser, login, setLoggingIn, setCurrentPage } from '../../Store/Auth';
import Footer from '../../Components/Footer';
import Navigation from '../../Components/Navigation';
import Registration from '../../Modals/Registration';
import Login from '../../Modals/Login';

function Home() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const user = useAppSelector(getUser);
    const toastId = React.useRef(0);
    const [showLogin, setShowLogin] = useState(false);
    const [showRegistration, setShowRegistration] = useState(false);
    const [activationCode, setActivationCode] = useState<string | null>(null);
    const [recoveryCode, setRecoveryCode] = useState<string | null>(null);
    const [queryParams] = useSearchParams();
    // Get a specific query parameter
    

    useEffect(() => {
        dispatch(setCurrentPage("home"));
        setActivationCode(queryParams.get('ac'));
        setRecoveryCode(queryParams.get('ar'));
    }, []);

    useEffect(()=>{
        if (activationCode) {
            setShowLogin(true);
        }
    },[activationCode]);

    useEffect(() => {
        if (user.accessToken !== "" && user.status !== "loading"){
            //dispatch(setLoggingIn(false));
            //navigate('/members/login');
            navigate('/members');
        }
    }, [user]);

    const toggleLogin = (visible: boolean) => {
        setShowRegistration(false);
        setShowLogin(visible);
    }
 
    const toggleRegistration = (visible: boolean) => {
        setShowLogin(false);
        setShowRegistration(visible);
    }

    const showRegistrationModal = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        setShowRegistration(true);
    }

    return (
    <>
        <Navigation />
        <div className="text-center">

            <div style={{ marginTop: "60px", marginBottom: "20px", fontSize: "14pt", fontWeight: "bold", color: "gray" }}>
                Herd Your Resources with Ease
            </div>

            <h1 className="h1Home" style={{ marginBottom: "20px" }}>
                Get the Most Out of Your Team
            </h1>
            
            <p className="pHome">
                Stay on top of work management with Herdr.io. Quickly see who's cruising through tasks and who's overloaded, 
                helping you find the balance between "just enough" and "too much" work. Keep your team productive, 
                balanced, and your projects on track—without risking burnout.
            </p>

            <p style={{ marginTop: "20px" }}>
                <img className="imageHome" src='images/capacity-grid-2.png' alt='Project kanban board for managing tasks' 
                    style={{ padding: "10px", borderRadius: "5px", border: "1px solid #efefef" }} />
            </p>

            <p className="mt-4 pt-4 buttonHome">
                <Link to="/" className="orange-signup-button" onClick={(e) => showRegistrationModal(e)}>
                    Master Stress-Free Work
                </Link>
            </p>

            <h2 className="h1Home" style={{ marginBottom: "20px", marginTop: "50px" }}>
                Group Boards with Ease
            </h2>

            <p className="pHome">
                Herdr provides AI-driven roadmap, product, project, action plan, and resource management. 
                Simply share access and AI will do the rest as your teams go about managing and completing work in an agile manner.
            </p>

            <p style={{ marginTop: "20px" }}>
                <img className="imageHome" src='images/herdr-board-splash-3.png' alt='Project kanban board for managing tasks' 
                    style={{ padding: "10px", borderRadius: "5px", border: "1px solid #efefef" }} />
            </p>

            <h2 className="h1Home" style={{ marginBottom: "20px", marginTop: "50px" }}>
                Easily Manage Lists and Cards
            </h2>

            <p className="pHome">
                Keep your to-do lists and tasks in check without losing your sanity! Herdr.io lets you 
                effortlessly juggle lists and cards, moving things around as easily as you shuffle through 
                your morning coffee options. Stay organized, stay on track, and keep everything flowing 
                smoothly-no sweat required.
            </p>

            <p style={{ marginTop: "20px" }}>
                <img className="imageHome" src='images/herdr-board-splash-2.png' alt='Project kanban board for managing tasks' 
                    style={{ padding: "10px", borderRadius: "5px", border: "1px solid #efefef" }} />
            </p>

            <h2 className="h1Home" style={{ marginBottom: "20px", marginTop: "50px" }}>
                Streamline Work Management with List View and Action Plans
            </h2>

            <p className="pHome">
                A powerful work management tool can be the difference between meeting and missing deadlines. 
                With the right software, you can view tasks in a clean list format, build out detailed action plans, 
                and monitor team progress without the hassle of overly complex processes.
            </p>

            <p style={{ marginTop: "20px" }}>
                <img className="imageHome" src='images/list.png' alt='Work management to organize your tasks' 
                    style={{ padding: "10px", borderRadius: "5px", border: "1px solid #efefef" }} />
            </p>

            <h2 className="h1Home" style={{ marginBottom: "20px", marginTop: "50px" }}>
                Estimate vs. Actual: Optimize Workflows with Kanban
            </h2>

            <p className="pHome">
                Easily compare estimated vs. actual effort on Kanban cards to streamline work management. 
                Gain insights, refine team performance, and keep projects on track—perfect for small team 
                executives aiming to maximize efficiency.
            </p>

            <p style={{ marginTop: "20px" }}>
                <img className="imageHome" src='images/work-example.png' alt='Estimated and actual work times for managing work' 
                    style={{ padding: "10px", borderRadius: "5px", border: "1px solid #efefef" }} />
            </p>

            <h2 className="h1Home" style={{ marginBottom: "20px", marginTop: "50px" }}>
                Minimize Boards for Maximum Clarity
            </h2>

            <p className="pHome">
                Too many tasks on your Kanban board? Minimize clutter to improve focus and clarity. 
                Streamlined boards help small teams prioritize key tasks, boost productivity, and 
                enhance work management efficiency. Keep it clear, keep it moving.
            </p>

            <p style={{ marginTop: "20px" }}>
                <img className="imageHome" src='images/minimized-boards.png' alt='Minimize boards to better organize and manage work' 
                    style={{ padding: "10px", borderRadius: "5px", border: "1px solid #efefef" }} />
            </p>


{/*
            <p className="pHomeImage">
               <img src='images/herdr-board-splash.png' alt='Project kanban board for managing tasks' 
                style={{ width: "100%", borderRadius: "5px" }} />
            </p>
*/}

{/*
            <h2 className="pHome" style={{ fontWeight: "bold", fontSize: "16pt" }}>
                Why is Herdr free to use?
            </h2>
           
            <p className="pHome">
                Herdr is <strong>100% free-to-use as we continue to refine and enhance our product</strong>. 
                Our commitment to accessibility means that for the foreseeable future, you can enjoy our simple, 
                intuitive board with lists and cards without any cost. Herdr is currently best suited for task 
                management on computers or tablets due to its lack of mobile optimization.
            </p>
 */}
            <p className="mt-4 pt-4">
                <Link to="/" className="orange-signup-button" onClick={(e) => showRegistrationModal(e)}>
                    Sign up for FREE
                </Link>
            </p>
            <br/><br/>
        </div>
        <Login visible={showLogin} visibleHandler={toggleLogin} activationCode={activationCode} recoveryCode={recoveryCode} />
        <Registration visible={showRegistration} visibleHandler={toggleRegistration} />
        <Footer />
    </>
    );
}

export default Home;