import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../Core/store';
import { axiosPublic, axiosPrivate } from '../Core/axios';
import { sliceStatus } from '../Helpers/types';
import { AnalyticsCapacity } from '../Models/Requests/AnalyticsCapacity';
import { GetAnalyticsParams } from '../Models/Requests/GetAnalyticsParams';

export interface AnalyticsState {
  capacityGrid: Array<AnalyticsCapacity>;
  status: sliceStatus;
  errorMessage: string;
}

const initialState: AnalyticsState = {
  capacityGrid: [],
  status: "unset",
  errorMessage: "",
};

export const getAnalytics = createAsyncThunk(
  'api/analytics',
  async (params: GetAnalyticsParams) => {    
    return await axiosPrivate.post('api/analytics',
      JSON.stringify({
        projectId: params.projectId,
        reportType: params.reportType,
        reportScope: params.reportScope,
        reportPeriod: params.reportPeriod,
        //projectMembers: params.projectMembers,
        //timeZone: params.timeZone,
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true
      }
    ).then(
      (res) => {
        return res.data;
      }
    );
});

export const AnalyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAnalytics.fulfilled, (state, action) => {
        state.status = 'idle';
        state.capacityGrid = action.payload;
      })
      .addCase(getAnalytics.rejected, (state, action) => {
        state.status = 'failed';
        state.errorMessage = action.error.message as string;
      })
        /*let analytics: AnalyticsState = action.payload;
        state.capacityGrid = analytics.capacityGrid;
        state.showProjects = settings.showProjects;
        state.avatar = settings.avatar;
        state.defaultAvatarColor = settings.defaultAvatarColor;
        state.defaultAvatarFontColor = settings.defaultAvatarFontColor;
        state.userId = settings.userId;
      })*/
  },
});

export const getAnalyticsState = (state: RootState) => state.analytics;
export default AnalyticsSlice.reducer;
