import React, { CSSProperties } from 'react';
import { useEffect, useState, createElement } from 'react';
import { Link, useNavigate} from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../Core/hooks';
import { ContextMenu } from '../Models/ContextMenu';
//import { ContainerContextParam } from'../Models/Requests/ContainerContextParam';
import { AnalyticsContextParam } from '../Models/Requests/AnalyticsContextParam';
import { AiOutlineClose, AiOutlinePlusCircle } from "react-icons/ai";
import { FiFolderPlus } from 'react-icons/fi';
import { FaRegTrashAlt, FaPencilAlt } from 'react-icons/fa';
import { getProject } from "../Store/Project";
import { getAnalyticsState, getAnalytics } from '../Store/Analytics';
import { getSettings } from '../Store/Settings';
//import { Container } from "../Models/Container";
import { IconContext, IconType } from "react-icons";
import { MdArrowLeft, MdArrowRight, MdFilterList } from "react-icons/md";
import { BsSortUp } from "react-icons/bs";
import { FaUserCircle } from "react-icons/fa";
import Avatar from './Avatar';
import { ProjectMember } from '../Models/Requests/ProjectMember';
import { FiPlus, FiMinus } from "react-icons/fi";
import { formatWorkTime } from '../Helpers/formatWorkTime';
import CardContextMenu from '../Components/CardContext';
import { CardContextParam } from '../Models/Requests/CardContextParam';

const fns = require('date-fns');


//import AnalyticsCardContext from '../Components/AnalyticsCardContext';
//import { AnalyticsCardContextParam } from '../Models/Requests/AnalyticsCardContextParam';
//import AnalyticsContextMenu from './AnalyticsContextMenu';
//import DynamicFaIcon from './DynamicFaIcon';
//import PopupContextMenu from './PopupContext';
//import { PopupContextParam } from '../Models/Requests/PopupContextParam';

interface Props {
    params: AnalyticsContextParam | undefined;    
}

function AnalyticsContext(props: Props) {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const projectState = useAppSelector(getProject);
    const analyticsState = useAppSelector(getAnalyticsState);
    const settingsState = useAppSelector(getSettings);
    //const [containerToDelete, setContainerToDelete] = useState<number>(0);
    const [analyticsContext, setAnalyticsContext] = useState<ContextMenu>();
    const [cardContextParams, setCardContextParams] = useState<CardContextParam>();
    //const [analyticsCardContext, setAnalyticsCardContext] = useState<ContextMenu>();
    //const [analyticsCardContextParams, setAnalyticsCardContextParams] = useState<AnalyticsCardContextParam>();
    const [period, setPeriod] = useState("Week");
    const [reportScope, setReportScope] = useState("board");
    const [reportType, setReportType] = useState("capacitygrid");
    const [showingCardsForMember, setShowingCardsForMember] = useState<Array<ProjectMember | null>>([]);

    useEffect(() => {
        setShowingCardsForMember([]);
        let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
        if (props.params) {
            //let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
            if ((analyticsContext && analyticsContext.id !== props.params.id) || !analyticsContext) {
          
                let menuWidth = window.outerWidth * .9;
                let menuHeight = window.outerHeight * .8;
                let menuXStart = window.outerWidth * .5;
                let menuYStart = 20;

                if (menuXStart + menuWidth > window.outerWidth) {
                    menuXStart = window.outerWidth - menuWidth - 10;
                }

                contextMenu.id = props.params.id;
                contextMenu.x = menuXStart;
                contextMenu.y = menuYStart;
                contextMenu.width = menuWidth;
                contextMenu.height = menuHeight;
            }

            dispatch(getAnalytics({
                reportType: reportType, 
                reportScope: reportScope, 
                reportPeriod: period, 
                projectId: getProjectId(), 
                //projectMembers: projectState.projectMembers,
                //timeZone: settingsState.timeZone,
            }));
        } 


        setAnalyticsContext(contextMenu);
        //setAnalyticsContextParams(undefined);
    }, [props.params]);

    useEffect(() => {

        if (props.params) {


            dispatch(getAnalytics({
                reportType: reportType, 
                reportScope: reportScope, 
                reportPeriod: period, 
                projectId: getProjectId(), 
            }));
        } 


        //setAnalyticsContext(contextMenu);
    }, [projectState.cards]);


    const closeContextMenu = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        e.stopPropagation();
        closeMenu();
    }

    const closeMenu = () => {
        let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
        setAnalyticsContext(contextMenu);
        if (props.params) {
            props.params.parentCloseHandler();
        }
    }

    const changePeriod = (e: React.MouseEvent<HTMLDivElement>, period: string) => {
        e.preventDefault();
        setPeriod(period);
        dispatch(getAnalytics({
            reportType: reportType, 
            reportScope: reportScope, 
            reportPeriod: period, 
            projectId: getProjectId(),
            //projectMembers: projectState.projectMembers,
            //timeZone: settingsState.timeZone,
        }));
    }

    const changePeriodArrows = (e: React.MouseEvent<HTMLSpanElement>, adj: number) => {
        e.preventDefault();
        let newPeriod = "";
        if (adj === -1) {
            if (period === "Week") newPeriod = "Year";
            if (period === "Month") newPeriod = "Week";
            if (period === "Quarter") newPeriod = "Month";
            if (period === "Year") newPeriod = "Quarter";
        } else {
            if (period === "Week") newPeriod = "Month";
            if (period === "Month") newPeriod = "Quarter";
            if (period === "Quarter") newPeriod = "Year";
            if (period === "Year") newPeriod = "Week";
        }
        setPeriod(newPeriod);
        dispatch(getAnalytics({
            reportType: reportType, 
            reportScope: reportScope, 
            reportPeriod: newPeriod, 
            projectId: getProjectId(),
            //projectMembers: projectState.projectMembers,
            //timeZone: settingsState.timeZone,
        }));
    }

    const handleReportScopeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setReportScope(e.currentTarget.value);
        dispatch(getAnalytics({
            reportType: reportType, 
            reportScope: e.currentTarget.value, 
            reportPeriod: period, 
            projectId: getProjectId(),
            //projectMembers: projectState.projectMembers,
            //timeZone: settingsState.timeZone,
        }));
    }

    const handleReportTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setReportType(e.currentTarget.value);
       
        dispatch(getAnalytics({
            reportType: e.currentTarget.value, 
            reportScope: reportScope, 
            reportPeriod: period, 
            projectId: getProjectId(),
            //projectMembers: projectState.projectMembers,
            //timeZone: settingsState.timeZone,
        }));
    }

    const getProjectId = () => {
        let project = projectState.projects.find(p => p.loaded);
        if (project) {
            return project.id;
        } else {
            return 0;
        }
    }

    const toggleUserCards = (e: React.MouseEvent<HTMLSpanElement>, member: ProjectMember, index: number) => {
        
        e.preventDefault();
        let members: Array<ProjectMember | null> = [];
        
        if(showingCardsForMember[index]) {
            members = [...showingCardsForMember];
            members[index] = null;
            setShowingCardsForMember(members);
        } else {
            members = [...showingCardsForMember];
            members[index] = member;
            setShowingCardsForMember(members);
        }
        
    }

    const toggleCardContext = (e: React.MouseEvent<HTMLDivElement>, cardId: number) => {
        e.preventDefault();
        let project = projectState.projects.find(p => p.loaded);
        if (project) {
            setCardContextParams({ projectId: project.id, cardId: cardId, event: e });   
        }
    };
/*
    const toggleAnalyticsCardContextMenu = (e: React.MouseEvent<HTMLDivElement>, cardId: number) => {
        e.preventDefault();
        e.stopPropagation();
        setAnalyticsCardContextParams({ id: 1, event: e });   
    };
*/
    //let project = projectState.projects.find(p => p.loaded);
    //let container = projectState.containers.find(c => c.loaded);
 
    let green = "#abebc6";
    let yellow = "#f9e79f";
    let orange = "#edbb99";
    let red = "#e6b0aa ";

    const sContextMenu: React.CSSProperties = {
        position: "relative",
        top: (analyticsContext) ? analyticsContext.y : 0,
        width: (analyticsContext) ? analyticsContext.width : 0,
        //height: (analyticsContext) ? analyticsContext.height : 0,
    }
    /*
    let weekCounter:number [][] = new Array(10).fill(0).map(() => 
      new Array(12).fill(0)
    );*/
    //let teamMembers = projectState.projectMembers.filter(m => m.active);
    //let teamMembers = projectState.projectMembers.filter(m => m.active);
/*
    let teamMembers = tempTeamMembers.filter((obj1, i, arr) => 
        arr.findIndex(obj2 => (obj2.userId === obj1.userId)) === i
    )*/
    //console.log(teamMembers);
    let m1 = 0;
    let m2 = 0;
    let m3 = 0;
    let m4 = 0;
    let weekCounter: number [] = new Array(16).fill(0);
    let teamMemberTotalCapacity: number [] = new Array(16).fill(0);
    let bucketWorkLimit = 0;
    let dayNames: string [] = new Array(16).fill("");

    //let teamMemberRows = teamMembers.map((member, i) => {
    let teamMemberRows = analyticsState.capacityGrid.map((member, i) => {
        //let memberGrid = analyticsState.capacityGrid.find(c => c.userId === member.userId);
        //if (memberGrid) {
            //console.log(member);
        bucketWorkLimit = member.bucketWorkLimit;
        //let currentMember;// = member.users.
        
        if (i === 0) { /* load only the first time for later */
            for (let d=0; d<16; d++) {
                //console.log(memberGrid.dates[d]);
                //let utcVersion = new Date(memberGrid.dates[d]).toISOString();
                dayNames[d] = (fns.format(new Date(member.dates[d]), 'eee').toUpperCase());
                //console.log(dayNames);
            }
        }
        
        teamMemberTotalCapacity[i] = member.estimatedWork.reduce((partialSum, a) => partialSum + a, 0);
        for (let b = 0; b < 16; b++) {
            weekCounter[b] += member.estimatedWork[b];
        }
        m1 += member.estimatedWork[0] + member.estimatedWork[1] + member.estimatedWork[2] + member.estimatedWork[3];
        m2 += member.estimatedWork[4] + member.estimatedWork[5] + member.estimatedWork[6] + member.estimatedWork[7];
        m3 += member.estimatedWork[8] + member.estimatedWork[9] + member.estimatedWork[10] + member.estimatedWork[11];
        m4 += member.estimatedWork[12] + member.estimatedWork[13] + member.estimatedWork[14] + member.estimatedWork[15];
        //console.log("M1: " + m1 + " M2: " + m2 +  "M3: " + m3 + "M4: " + m4);
        let capacity: Array<number> = [];
        let capacityColor: Array<string> = [];
        let capacityStyle: Array<CSSProperties> = [];

        for (let b = 0; b < 16; b++) {
            capacity[b] = Math.ceil(member.estimatedWork[b] / bucketWorkLimit * 100);
            //console.log(memberGrid.estimatedWork[b]);
            capacityColor[b] = (capacity[b] <= 40) ? yellow : (capacity[b] > 40 && capacity[b] <= 70) ? green : (capacity[b] > 70 && capacity[b] <= 80) ? orange : red;
            capacityStyle[b] = {
                fontSize: "11pt",
                backgroundColor: capacityColor[b],
                borderLeft: "1px solid #fff",
                borderTop: "1px solid #fff"
            }
        } 

        let memberCards = member.cards.map((c,i) => {
            let rStyle = (i % 2 === 0) ? { backgroundColor: "#f7f9f9", cursor: "pointer" } : { cursor: "pointer" };
            let sStyle = (c.status === "Started") ? { color: "#16a085" } : (c.status === "Open") ? { color: "#e74c3c" } : {};
            return (    
            <div className="row mt-2" style={rStyle} onClick={(e) => toggleCardContext(e, c.id)}>
                <div className="col-1 text-start p-2">
                    H{c.ticketId}
                </div>
                <div className="col-6 text-start p-2">
                    {c.title}
                </div> 
                <div className="col-1 text-start p-2" style={sStyle}>
                    {c.status}
                </div>
                <div className="col-2 text-end p-2">
                    {formatWorkTime(c.estimatedWork)}
                </div>
                <div className="col-2 text-end p-2">
                    {formatWorkTime(c.actualWork)}
                </div>
            </div>
            );
        });

        //&gt; &or; <FiMinus />
        return (
            <>
            <div className="row g-0" style={{ color: "#555" }}>
                <div className="col-4 text-start">
                    <div className="row g-0" style={{ marginTop: "5px" }}>
                        <div className="col-1">
                            <span style={{ cursor: "pointer", position: "relative", top: "-3px", fontSize: "10pt", color: "gray", marginRight: "10px" }}
                                onClick={(e) => toggleUserCards(e, member.member, i)}
                            >
                                {showingCardsForMember[i] ?
                                    <FiMinus size={20} color="#d7dbdd" />
                                :
                                    <FiPlus size={20} color="#d7dbdd" />
                                }
                            </span>
                        </div>
                        {/*<div className="col-1"></div>*/}
                        <div className="col-1" style={{ position: "relative", top: "-3px" }}>
                            <Avatar size="xsmall" projectMember={member.member} inline={true} />
                            {/*
                                <IconContext.Provider value={{ size: "25", color: "#000", className: "avatarIcon" }}>
                                    <FaUserCircle />
                                </IconContext.Provider>
                            */}
                        </div>
                        <div className="col-10">
                            <div style={{ width: "90%", margin: "0px auto", backgroundColor: "rgb(240, 243, 244)", borderRadius: "50px" }}>
                                <span  style={{ display: "inline-block",
                                    width: `${(teamMemberTotalCapacity[i]/(bucketWorkLimit*16))*100}%`, backgroundColor: "#d7dbdd", borderRadius: "50px" }}>
                                    &nbsp;
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-2 text-center">
                    <div className="row g-0">
                        <div className="col-3 p-2" style={capacityStyle[0]}>
                            {capacity[0]}
                        </div>
                        <div className="col-3 p-2" style={capacityStyle[1]}>
                            {capacity[1]}
                        </div>
                        <div className="col-3 p-2"  style={capacityStyle[2]}>
                            {capacity[2]}
                        </div>
                        <div className="col-3 p-2"  style={capacityStyle[3]}>
                            {capacity[3]}
                        </div>
                    </div>
                </div>
                <div className="col-2 text-center">
                    <div className="row g-0">
                        <div className="col-3 p-2" style={capacityStyle[4]}>
                            {capacity[4]}
                        </div>
                        <div className="col-3 p-2" style={capacityStyle[5]}>
                            {capacity[5]}
                        </div>
                        <div className="col-3 p-2"  style={capacityStyle[6]}>
                            {capacity[6]}
                        </div>
                        <div className="col-3 p-2"  style={capacityStyle[7]}>
                            {capacity[7]}
                        </div>
                    </div>
                </div>
                <div className="col-2 text-center">
                    <div className="row g-0">
                        <div className="col-3 p-2" style={capacityStyle[8]}>
                            {capacity[8]}
                        </div>
                        <div className="col-3 p-2" style={capacityStyle[9]}>
                            {capacity[9]}
                        </div>
                        <div className="col-3 p-2"  style={capacityStyle[10]}>
                            {capacity[10]}
                        </div>
                        <div className="col-3 p-2"  style={capacityStyle[11]}>
                            {capacity[11]}
                        </div>
                    </div>
                </div>
                <div className="col-2 text-center">
                    <div className="row g-0">
                        <div className="col-3 p-2" style={capacityStyle[12]}>
                            {capacity[12]}
                        </div>
                        <div className="col-3 p-2" style={capacityStyle[13]}>
                            {capacity[13]}
                        </div>
                        <div className="col-3 p-2"  style={capacityStyle[14]}>
                            {capacity[14]}
                        </div>
                        <div className="col-3 p-2"  style={capacityStyle[15]}>
                            {capacity[15]}
                        </div>
                    </div>
                </div>
            </div>
            {showingCardsForMember[i] &&
                <div className="row g-0" style={{ width: "95%", margin: "5px auto 15px auto", fontSize: "11pt" }}>
                    <div className="col-12">
                        <div className="row mt-2" style={{ fontWeight: "bold" }}>
                            <div className="col-1 text-start p-2">
                                Card
                            </div>
                            <div className="col-6 text-start p-2">
                                Title
                            </div> 
                            <div className="col-1 text-start p-2">
                                Status
                            </div>
                            <div className="col-2 text-end p-2">
                                Est. Work
                            </div>
                            <div className="col-2 text-end p-2">
                                Act. Work
                            </div>
                        </div>
                        {memberCards}
                    </div>
                </div>
            }
            </>
        );
        //}
    });
    let rollupCapacity = bucketWorkLimit * (analyticsState.capacityGrid.length * 4); // 16
    //console.log("Limit:" + rollupCapacity + " m1: " + m1);
    m1 = Math.round(m1/rollupCapacity*100);
    m2 = Math.round(m2/rollupCapacity*100);
    m3 = Math.round(m3/rollupCapacity*100);
    m4 = Math.round(m4/rollupCapacity*100);
    
    let m1Color = (m1 <= 40) ? yellow : (m1 > 40 && m1 <= 70) ? green : (m1 > 70 && m1 <= 80) ? orange : red;
    let m2Color = (m2 <= 40) ? yellow : (m2 > 40 && m2 <= 70) ? green : (m2 > 70 && m2 <= 80) ? orange : red;
    let m3Color = (m3 <= 40) ? yellow : (m3 > 40 && m3 <= 70) ? green : (m3 > 70 && m3 <= 80) ? orange : red;
    let m4Color = (m4 <= 40) ? yellow : (m4 > 40 && m4 <= 70) ? green : (m4 > 70 && m4 <= 80) ? orange : red;
    //console.log(weekCounter);
  
    let sPeriodToggleNotSelected: CSSProperties = {
        display: "inline-block",
        width: "50px",
        textAlign: "center",
        cursor: "pointer",
    }

    let sPeriodToggleSelected: CSSProperties = {
        display: "inline-block",
        width: "50px",
        textAlign: "center",
        backgroundColor: "#fff",
        color: "#000",
        borderRadius: "5px",
    }

    let sWkToggle = (period === "Week") ? sPeriodToggleSelected : sPeriodToggleNotSelected; 
    let sMoToggle = (period === "Month") ? sPeriodToggleSelected : sPeriodToggleNotSelected;
    let sQtrToggle = (period === "Quarter") ? sPeriodToggleSelected : sPeriodToggleNotSelected;
    let sYrToggle = (period === "Year") ? sPeriodToggleSelected : sPeriodToggleNotSelected;

    /********************************
     * Period Headers
     ********************************/

    /* This is just a poor man's way to use business day labels. */
    /*
    let dayOfWeek = new Date().getDay();
    let dayNames = Array(16);
    if (dayOfWeek === 0 || dayOfWeek === 6) {
        dayNames = ['MON','TUE','WED','THU','FRI','MON','TUE','WED','THU','FRI','MON','TUE','WED','THU','FRI','MON'];
    } else {
        dayNames = ['FRI','MON','TUE','WED','THU','FRI','MON','TUE','WED','THU','FRI','MON','TUE','WED','THU','FRI'];
    }*/
    //console.log(dayOfWeek); // 0=Sun 6=Sat
    //console.log(teamMembers.length);
    let periodHeaders = Array.from(Array(16), (e, i) => {
        if (i === 0 || i === 4 || i === 8 || i === 12) {
            let periodNumber = (i === 0) ? 1 : (i === 4) ? 2 : (i === 8) ? 3 : 4; 
            let periodCapacity = (i === 0) ? m1 : (i === 4) ? m2 : (i === 8) ? m3 : m4; 
            let divWidth = (i === 0) ? m1 : (i === 4) ? m2 : (i === 8) ? m3 : m4;
            let divColor = (i === 0) ? m1Color : (i === 4) ? m2Color : (i === 8) ? m3Color : m4Color;

            return (
                <div className="col-2 text-center">
                    <div className="analyticsMonth">{period} {periodNumber}</div>
                    <div style={{ backgroundColor: "#f0f3f4", borderRadius: "50px", marginLeft: "40px", marginRight: "40px" }}>
                        <div style={{ width: `${divWidth}%`, backgroundColor: `${divColor}`, borderRadius: "50px", fontSize: "11pt" }}>
                            {periodCapacity}
                        </div>
                    </div>
                    <div className="row g-0">
                        <div className="col-3" style={{ marginTop: "10px", marginBottom: "5px" }}>
                            <div style={{ fontSize: "10pt", textAlign: "center", fontWeight: "bold" }}>
                                {period === "Week" ? dayNames[i] : (period === "Month") ? "W" + (i+1) : (period === "Quarter") ? "M" + (i+1) : "Q" + (i+1)}
                            </div>
                        </div>
                        <div className="col-3" style={{ marginTop: "10px", marginBottom: "5px" }}>
                            <div style={{ fontSize: "10pt", textAlign: "center", fontWeight: "bold" }}>
                                {period === "Week" ? dayNames[i+1] : (period === "Month") ? "W" + (i+2) : (period === "Quarter") ? "M" + (i+2) : "Q" + (i+2)}
                            </div>
                        </div>
                        <div className="col-3" style={{  marginTop: "10px", marginBottom: "5px" }}>
                            <div style={{ fontSize: "10pt", textAlign: "center", fontWeight: "bold" }}>
                                {period === "Week" ? dayNames[i+2] : (period === "Month") ? "W" + (i+3) : (period === "Quarter") ? "M" + (i+3) : "Q" + (i+3)}
                            </div>
                        </div>
                        <div className="col-3" style={{ marginTop: "10px", marginBottom: "5px" }}>
                            <div style={{ fontSize: "10pt", textAlign: "center", fontWeight: "bold" }}>
                                {period === "Week" ? dayNames[i+3] : (period === "Month") ? "W" + (i+4) : (period === "Quarter") ? "M" + (i+4) : "Q" + (i+4)}
                            </div>
                        </div>
                    </div>
                    <div className="row g-0">
                        <div className="col-3" style={{ height: "41px", backgroundColor: "#f0f3f4", borderLeft: "1px solid #fff" }}>
                            <div style={{  position: "relative", top: `${100-(weekCounter[i]/(analyticsState.capacityGrid.length * bucketWorkLimit)*100)}%`,
                                height: `${weekCounter[i]/(analyticsState.capacityGrid.length * bucketWorkLimit)*100}%`, backgroundColor: "#d7dbdd" }}>
                                &nbsp;
                            </div>
                        </div>
                        <div className="col-3" style={{ height: "41px", backgroundColor: "#f0f3f4", borderLeft: "1px solid #fff" }}>
                            <div style={{  position: "relative", top: `${100-(weekCounter[i+1]/(analyticsState.capacityGrid.length * bucketWorkLimit)*100)}%`,
                                height: `${weekCounter[i+1]/(analyticsState.capacityGrid.length * bucketWorkLimit)*100}%`, backgroundColor: "#d7dbdd" }}>
                                &nbsp;
                            </div>
                        </div>
                        <div className="col-3" style={{ height: "41px", backgroundColor: "#f0f3f4", borderLeft: "1px solid #fff" }}>
                            <div style={{  position: "relative", top: `${100-(weekCounter[i+2]/(analyticsState.capacityGrid.length * bucketWorkLimit)*100)}%`,
                                height: `${weekCounter[i+2]/(analyticsState.capacityGrid.length * bucketWorkLimit)*100}%`, backgroundColor: "#d7dbdd" }}>
                                &nbsp;
                            </div>
                        </div>
                        <div className="col-3" style={{ height: "41px", backgroundColor: "#f0f3f4", borderLeft: "1px solid #fff" }}>
                            <div style={{  position: "relative", top: `${100-(weekCounter[i+3]/(analyticsState.capacityGrid.length * bucketWorkLimit)*100)}%`,
                                height: `${weekCounter[i+3]/(analyticsState.capacityGrid.length * bucketWorkLimit)*100}%`, backgroundColor: "#d7dbdd" }}>
                                &nbsp;
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    });


    return (
        <>
        {analyticsContext && analyticsContext.id > 0 &&
            <div onClick={e => closeMenu()} className="windowContainer">
                <div className="cardContextMenu" style={sContextMenu} onClick={e => { e.preventDefault(); e.stopPropagation(); }}>
                    {/*
                    <div className="divClose">
                        <Link to="" onClick={(e) => closeContextMenu(e)}>
                            <AiOutlineClose color="#555" size="13" />
                        </Link>
                    </div>
                    */}
                    {analyticsState.capacityGrid.length === 0 ?
                        <div style={{ margin: "0px auto", textAlign: "center"}}>
                            <img src='images/loading-1.gif' style={{ width: "300px" }} />
                        </div>
                    :
                        <div className="sharingContext" style={{ margin: "auto 0px", textAlign: "center" }}
                            onClick={(e) => { e.stopPropagation(); }}>
                            <div style={{ width: "100%", margin: "0px auto" }}>
                                <div className="row g-0" style={{ color: "#000" }}>
                                    <div className="col-4 text-start">
                                        <select style={{ padding: "5px", fontSize: "12pt", color: "#efefef", backgroundColor: "#000" }}
                                            onChange={(e) => handleReportScopeChange(e)}
                                            value={reportScope}
                                        >
                                            <option value='board'>This Board</option>
                                            <option value='container'>This Container</option>
                                            <option value='all'>All Boards & Containers</option>
                                        {/*
                                        <option>All Boards</option>
                                        <option>This Container</option>
                                        <option>All Containers</option>
                                        */}
                                        </select>&nbsp;&nbsp;
                                        <select style={{ padding: "5px", fontSize: "12pt", color: "#efefef", backgroundColor: "#000" }}
                                            onChange={(e) => handleReportTypeChange(e)}
                                            value={reportType}
                                        >
                                        <option value="capacitygrid">Capacity Grid</option>
                                        </select>
                                    </div>
                                    <div className="col-4 text-center">
                                        <span onClick={(e) => changePeriodArrows(e, -1)} style={{ cursor: "pointer" }}>
                                            <MdArrowLeft size={40} />
                                        </span>
                                        This {period} 
                                        <span onClick={(e) => changePeriodArrows(e, 1)} style={{ cursor: "pointer" }}>
                                            <MdArrowRight size={40} />
                                        </span>
                                    </div>
                                    <div className="col-4 text-end">
                                        <div className="ms-auto" style={{ 
                                            borderRadius: "5px", 
                                            backgroundColor: "#000",
                                            color: "#fff",
                                            width: "fit-content",
                                            padding: "5px"
                                        }}>
                                            <div style={sWkToggle} onClick={(e) => changePeriod(e, "Week")}>
                                                Wk
                                            </div>
                                            <div style={sMoToggle} onClick={(e) => changePeriod(e, "Month")}>
                                                Mo
                                            </div>
                                            <div style={sQtrToggle} onClick={(e) => changePeriod(e, "Quarter")}>
                                                Qtr
                                            </div>
                                            <div style={sYrToggle} onClick={(e) => changePeriod(e, "Year")}>
                                                Yr
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* header row */}
                                <div className="row g-0" style={{ color: "#555" }}>
                                    <div className="col-4 text-start">
                                        <div className="row" style={{ padding: "5px" }}>
                                            <div className="col-12" style={{ fontSize: "10pt", marginTop: "85px" }}>
                                            </div>
                                        </div>
                                        {/*
                                        <div style={{ padding: "5px", fontSize: "10pt" }}>
                                            <MdFilterList size={18} /> Filter&nbsp;&nbsp;&nbsp;&nbsp;
                                            <BsSortUp size={18} /> Sort
                                        </div>
                                        */} 
                                        <div className="row g-0">
                                            <div className="col-12">
                                                <span style={{ paddingLeft: "5px", color: "gray", fontSize: "11pt" }}>
                                                    {analyticsState.capacityGrid.length} members
                                                </span>
                                            </div>  
                                        </div>
                                    </div>
                                    {periodHeaders}
                                </div>
                                {/* detail rows */}
                                {teamMemberRows}
                            </div>
                        </div>
                    }
                </div>
            </div>
        }
        <CardContextMenu params={cardContextParams} />
            {/*<AnalyticsCardContext params={analyticsCardContextParams} />*/}
        </>
    );
}

export default AnalyticsContext;