import { useAppSelector, useAppDispatch } from '../Core/hooks';
import { CSSProperties, useState } from 'react';
//import { getUser } from '../Store/Auth';
//import { getSettings } from '../Store/Settings';
import { getProject, setReportFilters,setReportFilterValues, setReportSort, setReportSortDirection } from '../Store/Project';
import { getUser } from '../Store/Auth';
import { getSettings } from '../Store/Settings';
import { MdArrowLeft, MdArrowRight, MdFilterList } from "react-icons/md";
import { AiOutlineDownload } from "react-icons/ai";
import { BsSortUp } from "react-icons/bs";
import FilterCardContextMenu from './FilterCardContextMenu';
import GroupCardContextMenu from './GroupCardContextMenu';
import { FilterCardContextMenuParams } from '../Models/Requests/FilterCardContextMenuParams';
import { GroupCardContextMenuParams } from '../Models/Requests/GroupCardContextMenuParams';
import { format } from 'date-fns';
import { formatWorkTime } from '../Helpers/formatWorkTime';
import { Card } from '../Models/Card';
import { List } from '../Models/List';
import { ProjectMember } from '../Models/Requests/ProjectMember';
import Avatar from "../Components/Avatar";
import CardContextMenu from '../Components/CardContext';
import { CardContextParam } from '../Models/Requests/CardContextParam';
//import { ProjectMember } from "../Models/Requests/ProjectMember";
/*
interface Props {
    size: string;
    projectMember?: ProjectMember;
    inline?: boolean;
}
*/
//function Avatar(props: Props) {
function ActionPlan() {
    //const user = useAppSelector(getUser);
    const projectState = useAppSelector(getProject);
    const userState = useAppSelector(getUser);
    const settingsState = useAppSelector(getSettings);
    const dispatch = useAppDispatch();
    const [filterCardContextMenuParams, setFilterCardContextMenuParams] = useState<FilterCardContextMenuParams>();
    const [groupCardContextMenuParams, setGroupCardContextMenuParams] = useState<GroupCardContextMenuParams>();
    const [cardContextParams, setCardContextParams] = useState<CardContextParam>();
   /*
    const closeMenu = () => {
        let contextMenu = { id: 0, x: 0, y: 0, width: 0, height: 0 };
        setFilterCardStatusContextMenuParams(undefined);
    }
*/
    const handleFilterCardStatus = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setFilterCardContextMenuParams({ event: e, id: 1 });
    }

    const handleGroupCardStatus = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setGroupCardContextMenuParams({ event: e, id: 1 });
    }

    const clearReportFilter = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(setReportFilters([]));
        dispatch(setReportFilterValues([]));
    }

    const clearReportGroup = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(setReportSort(""));
    }

    const toggleCardContext = (e: React.MouseEvent<HTMLDivElement>, cardId: number) => {
        e.preventDefault();
        let project = projectState.projects.find(p => p.loaded);
        if (project) {
            setCardContextParams({ projectId: project.id, cardId: cardId, event: e });   
        }
    };

    let project = projectState.projects.find(p => p.loaded);
    let cards: Array<Card> = [...projectState.cards];
    let lists: Array<List> = [...projectState.lists];
    let owners: Array<ProjectMember> = [...projectState.projectMembers];
    let sortedCards: Array<Card> = [];
    let reportFilters = projectState.reportFilters;
    let reportFilterValues = projectState.reportFilterValues;

    if (projectState.reportSort === "") {
        sortedCards = cards;
    } else if (projectState.reportSort === "Card") {
        sortedCards = cards;
        if (projectState.reportSortDirection === "asc") {
            sortedCards.sort((a,b) => a.id - b.id);
        } else {
            sortedCards.sort((a,b) => b.id - a.id);
        }
    } else if (projectState.reportSort === "List") {
        if (projectState.reportSortDirection === "asc") {
            lists.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        } else {
            lists.sort((a,b) => (b.name > a.name) ? 1 : ((a.name > b.name) ? -1 : 0));
        }
        lists.forEach((l,i) => {
            let tempCards = cards.filter(c => c.listId === l.id);
            tempCards.forEach((c,i) => {
                sortedCards.push(c);
            });
        });
    } else if (projectState.reportSort === "Status") {
        if (projectState.reportSortDirection === "asc") {
            sortedCards = cards.sort((a,b) => (a.status > b.status) ? 1 : ((b.status > a.status) ? -1 : 0));
        } else {
            sortedCards = cards.sort((a,b) => (b.status > a.status) ? 1 : ((a.status > b.status) ? -1 : 0));
        }
    } else if (projectState.reportSort === "Title") {
        if (projectState.reportSortDirection === "asc") {
            sortedCards = cards.sort((a,b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0));
        } else {
            sortedCards = cards.sort((a,b) => (b.title > a.title) ? 1 : ((a.title > b.title) ? -1 : 0));
        }
    } else if (projectState.reportSort === "Owner") {
        if (projectState.reportSortDirection === "asc") {
            owners.sort((a,b) => (a.firstName > b.firstName) ? 1 : ((b.firstName > a.firstName) ? -1 : 0));
        } else {
            owners.sort((a,b) => (b.firstName > a.firstName) ? 1 : ((a.firstName > b.firstName) ? -1 : 0));
        }
        owners.forEach((o,i) => {
            let tempCards = cards.filter(c => c.ownerId === o.userId);
            tempCards.forEach((c,i) => {
                sortedCards.push(c);
            });
        });
    } else if (projectState.reportSort === "Created") {
        if (projectState.reportSortDirection === "asc") {
            sortedCards = cards.sort((a,b) => (a.created > b.created) ? 1 : ((b.created > a.created) ? -1 : 0));
        } else {
            sortedCards = cards.sort((a,b) => (b.created > a.created) ? 1 : ((a.created > b.created) ? -1 : 0));
        }
    } else if (projectState.reportSort === "Estimated") {
        //sortedCards = cards;
        /*if (projectState.reportSortDirection === "asc") {
            sortedCards = cards.sort((a,b) => (a.estimatedWork > b.estimatedWork) ? 1 : ((b.estimatedWork > a.estimatedWork) ? -1 : 0));
        } else {
            sortedCards = cards.sort((a,b) => (b.estimatedWork > a.estimatedWork) ? 1 : ((a.estimatedWork > b.estimatedWork) ? -1 : 0));
        }*/
        
        if (projectState.reportSortDirection === "asc") {
            sortedCards = cards.map(sc => {
                if (sc.estimatedWork === undefined) {
                    console.log(sc.estimatedWork);
                    return {
                        ...sc,
                        estimatedWork: 0 
                    };
                } else {
                    return { ...sc };
                }
      
            });
            sortedCards.sort((a,b) => a.estimatedWork - b.estimatedWork);
        } else {
            sortedCards = cards.map(sc => {
                if (sc.estimatedWork === undefined) {
                    console.log(sc.estimatedWork);
                    return {
                        ...sc,
                        estimatedWork: 0 
                    };
                } else {
                    return { ...sc };
                }
      
            });

            sortedCards.sort((a,b) => b.estimatedWork - a.estimatedWork);
        }
        
    } else if (projectState.reportSort === "Actual") {
        if (projectState.reportSortDirection === "asc") {
            sortedCards = cards.map(sc => {
                if (sc.actualWork === undefined) {
                    console.log(sc.actualWork);
                    return {
                        ...sc,
                        actualWork: 0 
                    };
                } else {
                    return { ...sc };
                }
            });
            sortedCards.sort((a,b) => a.actualWork - b.actualWork);
        } else {
            sortedCards = cards.map(sc => {
                if (sc.actualWork === undefined) {
                    console.log(sc.actualWork);
                    return {
                        ...sc,
                        actualWork: 0 
                    };
                } else {
                    return { ...sc };
                }
            });
            sortedCards.sort((a,b) => b.actualWork - a.actualWork);
        }
    } else if (projectState.reportSort === "Comments") {
        //sortedCards = cards;
        let cardCommentCounts:number[][] = [];

        cards.forEach((c,i) => {
            cardCommentCounts[i] = new Array(2);
            cardCommentCounts[i][0] = c.id;
            cardCommentCounts[i][1] = projectState.comments.filter(cc => c.id === cc.cardId).length;
        })

        if (projectState.reportSortDirection === "asc") {
            cardCommentCounts.sort(
                function compareSecondColumn(a, b) {
                    if (a[1] === b[1]) {
                        return 0;
                    }
                    else {
                        return (a[1] < b[1]) ? -1 : 1;
                    }
                }
            );
        } else { 
            cardCommentCounts.sort(
                function compareSecondColumn(a, b) {
                    if (b[1] === a[1]) {
                        return 0;
                    }
                    else {
                        return (b[1] < a[1]) ? -1 : 1;
                    }
                }
            );
        }
        cardCommentCounts.forEach((c,i) => {
            let card = cards.find(cc => cc.id === c[0]);
            if (card) {
                sortedCards.push(card);
            }
        });
    }
    
    //let sortedBy = projectState.reportGroup;

    let cardWidth = "5%";
    let listWidth = "12%";
    let titleWidth = "21%";
    let statusWidth = "7%";
    let labelWidth = "13%";
    let ownerWidth = "12%";
    let createdWidth = "5%";
    let estimatedWidth = "9%";
    let actualWidth = "9%";
    let commentWidth = "2%";  
    let columnHeight = "40px";   

        /* Card filtering based on filters */

        if (reportFilters.length > 0 && reportFilterValues.length > 0) {

            let cardIdsToRemove: Array<number> = [];
            //let safe = false;

            sortedCards.forEach((c,i) => {
                
                let values = 0, notFoundValues = 0;

                // Lists     
                if (reportFilters.includes("List")) {
                    let list = projectState.lists.find(l => l.id === c.listId);
                    reportFilterValues.forEach(v => {
                        values++;
                        if (!list?.name.toLocaleLowerCase().includes(v.toLocaleLowerCase())) {
                            notFoundValues++;
                        }
                    });
                }

                // title
                if (reportFilters.includes("Title")) {
                    reportFilterValues.forEach(v => {
                        values++;
                        if (!c.title.toLocaleLowerCase().includes(v.toLocaleLowerCase())) {
                            notFoundValues++;
                        }
                    });
                }

                // status
                if (reportFilters.includes("Status")) {
                    reportFilterValues.forEach(v => {
                        values++;
                        if (!c.status.toLocaleLowerCase().includes(v.toLocaleLowerCase())) {
                            notFoundValues++;
                        }
                    });
                }
                
                // Label  
                if (reportFilters.includes("Label")) {
                    let labelsToCards = projectState.labelsToCards.filter(lc => lc.cardId === c.id);
                    let labelsString = "";
                    let formattedLabels = labelsToCards.forEach((lc,i) => {
                        let label = projectState.labels.find(ll => ll.id === lc.labelId);
                        labelsString += (label?.title);
                        labelsString += (labelsToCards.length > i+1) ? ", " : "";
                    });

                    reportFilterValues.forEach(v => {
                        values++;
                        if (!labelsString.toLocaleLowerCase().includes(v.toLocaleLowerCase())) {
                            notFoundValues++;
                        }
                    });
                }

                // owner
                if (reportFilters.includes("Owner")) {
                    let member = projectState.projectMembers.find(pm => pm.userId === c.ownerId);
                    if (member !== undefined) {
                        let name = member?.firstName + " " + member?.lastName;
                        reportFilterValues.forEach(v => {
                            values++;
                            if (!name.toLocaleLowerCase().includes(v.toLocaleLowerCase())) {
                                notFoundValues++;
                            }
                        });
                    } else {
                        values++;
                        notFoundValues++;
                    }
                }

                // Include or Exclude
                if (values === notFoundValues) {
                    cardIdsToRemove.push(c.id);
                }

            });
            cardIdsToRemove.forEach(id => {
                let index = sortedCards.findIndex(c => c.id === id);
                if (index !== -1) {
                    sortedCards.splice(index,1);
                }
            })
        }
    
        /* Card sorting based on sorts */
         
    let formattedCards = sortedCards.map((c, i) => {

        let backgroundColor = (i % 2 == 0) ? "#fbfafa": "#fff";
        let list = projectState.lists.find(l => l.id === c.listId);
        let labelsToCards = projectState.labelsToCards.filter(lc => lc.cardId === c.id);
        //let labelsString = "";
        let labels: Array<any> = [];
        let formattedLabels = labelsToCards.forEach((lc,i) => {
           if (i < 1) { // limit to 2 labels
                let label = projectState.labels.find(ll => ll.id === lc.labelId);
                let style = {
                    backgroundColor: label?.backgroundColor,
                    color: label?.fontColor,
                    padding: "2px 5px",
                    borderRadius: "2px",  
                    marginRight: "2px"
                    //cursor: "auto",
                }
                labels.push(<span style={style}>{label?.title}</span>);
            } else if (i === 1) {
                labels.push(<span>...</span>);
            }
            //labelsString += (labelsToCards.length > i+1) ? ", " : "";
        });
/*
        if (labelsString === "") {
            labelsString = "-";
        }
*/
        let member = projectState.projectMembers.find(pm => pm.userId === c.ownerId);
        let comments = projectState.comments.filter(cc => c.id === cc.cardId);
        let name = (member !== undefined) ? member.firstName + " " + member.lastName : "-";
        let fontSize = "10pt";
        //let fontSize = (settingsState.showProjects) ? "9pt" : "10pt";
        let status = c.status;
        let eStatusStyle: CSSProperties = {
            padding: "2px 5px", 
            borderRadius: "2px", 
            fontWeight: "bold", 
            fontSize: (settingsState.showProjects) ? "7.5pt" : "9pt"
        }

        if(status === "Completed") {
            eStatusStyle.color = "#1d8348";
            eStatusStyle.backgroundColor = "#eafaf1";
        } else if(status === "Started") {
            eStatusStyle.color = "#9a7d0a";
            eStatusStyle.backgroundColor = "#fef9e7";
        } else {
            eStatusStyle.color = "#943126";
            eStatusStyle.backgroundColor = "#fdedec";
        }

        let eStatus = (
            <span style={eStatusStyle}>
                {status.toUpperCase()}
            </span>
        )

        let createdDay = (
            <span style={{ backgroundColor: "#efefef", borderRadius: "2px", padding: "2px 5px" }}>
                {format(new Date(c.created), 'MMM d')}
            </span>
        );

        return (
            <div style={{ fontSize: `${fontSize}`, backgroundColor: `${backgroundColor}`, cursor: "pointer" }} 
                onClick={(e) => toggleCardContext(e, c.id)}>
                <div style={{ borderTop: "1px solid #efefef", borderLeft: "1px solid #efefef", padding: "5px", display: "inline-block", width: `${cardWidth}`, textAlign: "center" }}>
                    {c.ticketId ? c.ticketId : "-"}
                </div>
                <div style={{ borderTop: "1px solid #efefef", borderLeft: "1px solid #efefef", padding: "5px", display: "inline-block", width: `${listWidth}` }}>
                    {list && list?.name.length > 20 ? list?.name.substring(0,20) + "..." : list?.name}
                </div>
                <div style={{ borderTop: "1px solid #efefef", borderLeft: "1px solid #efefef", padding: "5px", display: "inline-block", width: `${titleWidth}` }}>
                    {c.title.length > 36 ? c.title.substring(0,36) + "..." : c.title}
                </div>
                <div style={{ textAlign: "center", borderTop: "1px solid #efefef", borderLeft: "1px solid #efefef", padding: "5px", display: "inline-block", width: `${statusWidth}` }}>
                    {eStatus}
                </div>
                <div style={{ borderTop: "1px solid #efefef", borderLeft: "1px solid #efefef", padding: "5px", display: "inline-block", width: `${labelWidth}` }}>
                    {labels.length > 0 ? labels : "-"}
                </div>
                <div style={{ borderTop: "1px solid #efefef", borderLeft: "1px solid #efefef", padding: "5px", display: "inline-block", width: `${ownerWidth}` }}>
                    {member && <Avatar size="xxsmall" projectMember={member} inline={true} />}
                    <span style={{ paddingLeft: "5px" }}>{name.length > 20 ? name.substring(0,20) + "..." : name}</span>
                </div>
                <div style={{ textAlign: "center", borderTop: "1px solid #efefef", borderLeft: "1px solid #efefef", padding: "5px", display: "inline-block", width: `${createdWidth}` }}>
                    {createdDay}
                </div>
                <div style={{ borderTop: "1px solid #efefef", borderLeft: "1px solid #efefef", padding: "5px", display: "inline-block", width: `${estimatedWidth}` }}>
                    {formatWorkTime(c.estimatedWork)}
                </div>
                <div style={{ borderTop: "1px solid #efefef", borderLeft: "1px solid #efefef", padding: "5px", display: "inline-block", width: `${actualWidth}` }}>
                    {formatWorkTime(c.actualWork)}
                </div>
                <div style={{ borderTop: "1px solid #efefef", borderLeft: "1px solid #efefef", padding: "5px", display: "inline-block", width: `${commentWidth}`, textAlign: "center" }}>
                    {comments.length}
                </div>
            </div>
        );
    });

    let filters = projectState.reportFilters.map((f,i) => {
        return (
            <span style={{ fontWeight: "bold" }}>
                {f}{i+1 !== projectState.reportFilters.length && ", "}
            </span>
        );
    });

    let filterValues = projectState.reportFilterValues.map((f,i) => {
        return (
            <span style={{ fontWeight: "bold" }}>
                {f}{i+1 !== projectState.reportFilterValues.length && ", "}
            </span>
        );
    });

    return (
        <div style={{ height: "85vh", marginLeft: "15px" }}>
            <div>
                {projectState.reportSort === "" ?
                    <div className="ms-2" style={{ display: "inline-block", cursor: "pointer" }} onClick={(e) => handleGroupCardStatus(e)}>
                        <BsSortUp size="24" color="gray" />
                    </div>
                :
                    <div className="ms-2 reportButton" onClick={(e) => handleGroupCardStatus(e)}>
                        Sorted by: <b>{projectState.reportSort}</b>
                        <span className="ps-2" style={{ cursor: "pointer" }} onClick={(e) => clearReportGroup(e)}>
                            x
                        </span>
                    </div>
                }
                {projectState.reportFilters.length === 0 ?
                    <div className="ms-2" style={{ display: "inline-block", cursor: "pointer" }} onClick={(e) => handleFilterCardStatus(e)}>
                        <MdFilterList size="24" color="gray" />
                    </div>
                :
                    <div className="ms-2 reportButton" onClick={(e) => handleFilterCardStatus(e)}>
                        Filters: {filters} &nbsp;
                        Values: {filterValues}
                        <span className="ps-2" style={{ cursor: "pointer" }} onClick={(e) => clearReportFilter(e)}>
                            x
                        </span>
                    </div>
                }
                {/*
                <div className="ms-2" style={{ display: "inline-block", cursor: "pointer" }} onClick={(e) => alert("Download needs implemented")}>
                    <AiOutlineDownload size="24" color="gray" />
                </div>
                */}
            </div>

            <div className="mt-2">
                <div style={{ fontWeight: "bold", fontSize: "10pt" }}>
                    <div style={{ padding: "5px", display: "inline-block", width: `${cardWidth}`, textAlign: "center" }}>
                        Card
                    </div>
                    <div style={{ padding: "5px", display: "inline-block", width: `${listWidth}` }}>
                        List
                    </div>
                    <div style={{ padding: "5px", display: "inline-block", width: `${titleWidth}` }}>
                        Title
                    </div>
                    <div style={{ textAlign: "center", padding: "5px", display: "inline-block", width: `${statusWidth}` }}>
                        Status
                    </div>
                    <div style={{ padding: "5px", display: "inline-block", width: `${labelWidth}` }}>
                        Label(s)
                    </div>
                    <div style={{ padding: "5px", display: "inline-block", width: `${ownerWidth}` }}>
                        Owner
                    </div>
                    <div style={{ textAlign: "center", padding: "5px", display: "inline-block", width: `${createdWidth}` }}>
                        Created
                    </div>
                    <div style={{ padding: "5px", display: "inline-block", width: `${estimatedWidth}` }}>Estimated</div>
                    <div style={{ padding: "5px", display: "inline-block", width: `${actualWidth}` }}>Actual</div>
                    <div style={{ padding: "5px", display: "inline-block", width: `${commentWidth}`, textAlign: "center" }}>C</div>
                </div>
                {formattedCards.length > 0 ? formattedCards : 
                    <div style={{width: "100%", margin: "50px auto", textAlign: "center", fontWeight: "bold", fontStyle: "italic", color: "gray" }}>
                        No cards found
                    </div>
                }
            </div>
            <FilterCardContextMenu params={filterCardContextMenuParams} />
            <GroupCardContextMenu params={groupCardContextMenuParams} />
            <CardContextMenu params={cardContextParams} />
        </div>
    );
}

export default ActionPlan;